@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700;800&family=Poppins:wght@200;300;400;500;700&display=swap');

:root {
  --brandColor: #e04444;
  --acentColor:#161F60;
  --acentColor2:#e04444;
  --textColor:#706F6F;
  --navTextColor:#000000;
  --bgColor:#e6007f21;

  --headingFont:'Montserrat', sans-serif;
    --textFont:'Poppins', sans-serif;

}

body {
  margin: 0;
  font-family:  var(--textFont) !important;
  background:#ffffff;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;

}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

 .ant-pagination-item {


  background-color: #ffffff !important;
  color: var(--brandColor) !important;
  box-shadow: 1px 2px 2px #a7a7a7 !important;
  border: none !important;
  border-radius: 5px !important;
  min-width: 30px;
   height: 30px; 


}

 .ant-pagination-item-active{

  background-color: var(--brandColor) !important;
  color: white !important;
  box-shadow: 1px 2px 2px #a7a7a7 !important;
  border: none !important;
  border-radius: 5px !important;
  min-width: 30px;
   height: 30px; 

}



.ant-pagination-item-link{

    border: none !important;

} 




.ant-table-wrapper {
  clear: both;
  max-width: 100%;
  height: 85vh !important;
  /* overflow-y: auto !important; */


  
}

.ant-carousel .slick-dots li{

  background-color: var(--acentColor);
  
  
  }
  .ant-carousel .slick-dots li.slick-active button{
  
  background:var(--brandColor) !important;
  
  
  }

  
::-webkit-scrollbar {
  width: 6px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1; 
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: rgba(255, 228, 239, 0.301); 
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555; 
}


.ant-table table {

  border-spacing: 0 0.8rem !important;
}

.ant-table-tbody > tr {

border-radius: 0.3rem !important;

}
.ant-table-tbody > tr > td {

  padding: 0.2rem 1rem !important;
  border-bottom: 0px solid #f0f0f0 !important;
  background-color: rgba(204, 186, 194, 0.116) !important;
}

.ant-table-thead > tr > th {
  color: rgb(255 229 229 / 85%) !important;
  background: #e04444 !important;
  padding: 0.2rem 0!important;
  border-bottom: 0px solid #f0f0f0!important;

  border-spacing: 0 !important;
}

.ant-table-thead > tr  {

  box-shadow: 0 1px 2px 1px rgb(172, 172, 172);
  transform: translateY(-5px);
  
}


.ant-layout.ant-layout-has-sider > .ant-layout {
  overflow-y: auto !important;
  height: 100% !important;
}


.ant-table-thead > tr > th {

  color: rgba(255, 255, 255, 0.828) !important;
  font-size: 1rem!important;
  text-transform: capitalize!important;
  padding: 0.3rem 0.9rem !important;
  border-bottom: 0px solid #f0f0f0!important;
}

.nav-link:hover {

  color:var(--brandColor) !important;
  
}


.ql-editor{

  min-height:15vh;
}

.ql-toolbar.ql-snow {

  background-color: #e4e4e4;
}


.ant-layout-content {

min-height: 80vh !important;

}

